import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import logo from 'assets/img/logo.png';
import logoWhite from 'assets/img/logo-white.png';
import logoFull from 'assets/img/logo-full.png';
import logoFullWhite from 'assets/img/logo-full-white.png';
import AppContext from 'context/Context';

const Logo = ({at, width, className, textClass, full, href, ...rest}) => {
    const {
        config: {isDark}
    } = useContext(AppContext);
    return (
        <>
            <Link to={href}
                  className={classNames('text-decoration-none',
                    {'navbar-brand text-left': at === 'navbar-vertical'},
                    {'navbar-brand text-left': at === 'navbar-top'}
                )}
                {...rest}>
                <div
                    className={classNames(
                        'd-flex',
                        {
                            'align-items-center py-3': at === 'navbar-vertical',
                            'align-items-center': at === 'navbar-top',
                            'flex-center fw-bolder fs-5 mb-4': at === 'auth'
                        }, className
                    )}>
                    <img className="me-2" src={isDark ? (full ? logoFullWhite : logoWhite) : (full ? logoFull : logo)}
                         alt="Logo" width={width}/>
                </div>
            </Link>
        </>
    );
};

Logo.propTypes = {
    at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
    width: PropTypes.number,
    className: PropTypes.string,
    textClass: PropTypes.string
};

Logo.defaultProps = {at: 'auth', href: '/', width: 120};

export default Logo;
