import axios from "axios";
import { BASE_URL } from "constants";
import { debounce } from 'lodash';
import { toast } from "react-toastify";
import { getToken, clearToken } from "helpers/utils"; 

let lastErrorTime = 0;
const errorCooldown = 5000;

const api = axios.create({
  baseURL: BASE_URL,
});

const showError = debounce((message) => {
	toast.error(message);
  }, errorCooldown, { leading: true, trailing: false });
  
  api.interceptors.response.use(
	(response) => response,
	(error) => {
	  const now = Date.now();
	  if (now - lastErrorTime > errorCooldown) {
		if (!error.response) {
		  // Network error (no response received)
		  showError("Network error: Can't reach the API");
		} else {
		  const { status } = error.response;
		  if (status === 401) {
			clearToken();
			toast.info("Session expired. Please log in again."); 
		  }
		}
		lastErrorTime = now;
	  }
	  error.message = error.response?.data?.detail ?? error.message;
	  return Promise.reject(error);
	}
  );

api.interceptors.request.use(
  (config) => {
    // Check if the request is not a login request
    if (!config.url.includes("/login")) {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
