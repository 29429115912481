import {useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {Navbar, Nav} from "react-bootstrap";
import classNames from "classnames";
import AppContext from "context/Context";
import Logo from "components/common/Logo";
import NavbarTopDropDownMenus from "./NavbarTopDropDownMenus";
import {navbarBreakPoint, topNavbarBreakpoint} from "config";
import TopNavRightSideNavItem from "./TopNavRightSideNavItem";


const NavbarTop = () => {
    const {
        config: {showBurgerMenu, navbarPosition, navbarCollapsed},
        setConfig,
    } = useContext(AppContext);

    const [showDropShadow, setShowDropShadow] = useState(false);

    const handleBurgerMenu = () => {
        (navbarPosition === "top" || navbarPosition === "double-top") &&
        setConfig("navbarCollapsed", !navbarCollapsed);
        (navbarPosition === "vertical" || navbarPosition === "combo") &&
        setConfig("showBurgerMenu", !showBurgerMenu);
    };

    const setDropShadow = () => {
        const el = document.documentElement;
        if (el.scrollTop > 0) {
            setShowDropShadow(true);
        } else {
            setShowDropShadow(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", setDropShadow);
        return () => window.removeEventListener("scroll", setDropShadow);
    }, []);

    const burgerMenuRef = useRef();

    return (
        <Navbar
            className={classNames("navbar-glass fs--1 navbar-top sticky-kit", {
                "navbar-glass-shadow": showDropShadow,
            })}
            expand={
                navbarPosition === "top" ||
                navbarPosition === "combo" ||
                navbarPosition === "double-top"
                    ? topNavbarBreakpoint
                    : true
            }
        >
            {navbarPosition === "double-top" ? (
                <div className="w-100">
                    <div className="d-flex flex-between-center">
                        <NavbarTopElements
                            navbarCollapsed={navbarCollapsed}
                            navbarPosition={navbarPosition}
                            handleBurgerMenu={handleBurgerMenu}
                            burgerMenuRef={burgerMenuRef}
                        />
                    </div>
                    <hr className="d-none d-lg-block my-2"/>
                    <Navbar.Collapse in={navbarCollapsed} className="scrollbar py-2">
                        <Nav navbar>
                            <NavbarTopDropDownMenus/>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            ) : (
                <NavbarTopElements
                    navbarCollapsed={navbarCollapsed}
                    navbarPosition={navbarPosition}
                    handleBurgerMenu={handleBurgerMenu}
                    burgerMenuRef={burgerMenuRef}
                />
            )}
        </Navbar>
    );
};

const NavbarTopElements = ({ navbarPosition, handleBurgerMenu, navbarCollapsed, data }) => {
    const burgerMenuRef = useRef();

    return (
        <>
            <Navbar.Toggle
                ref={burgerMenuRef}
                className={classNames("toggle-icon-wrapper me-md-3", {
                    "d-lg-none":
                        navbarPosition === "top" || navbarPosition === "double-top",
                    [`d-${navbarBreakPoint}-none`]:
                    navbarPosition === "vertical" || navbarPosition === "combo",
                })}
                as="div"
            >
                <button
                    className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
                    onClick={handleBurgerMenu}
                    id="burgerMenu"
                >
          <span className="navbar-toggle-icon">
            <span className="toggle-line"/>
          </span>
                </button>
            </Navbar.Toggle>

            <Logo at="navbar-top" id="topLogo"/>

            {navbarPosition === "top" || navbarPosition === "combo" ? (
                <Navbar.Collapse
                    in={navbarCollapsed}
                    className="scrollbar pb-lg-0 pb-3"
                >
                    <Nav navbar>
                        <NavbarTopDropDownMenus/>
                    </Nav>
                </Navbar.Collapse>
            ) : (
                <Nav
                    navbar
                    className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}
                    as="ul"
                >
                </Nav>
            )}
            <TopNavRightSideNavItem/>
        </>
    );
};

NavbarTopElements.propTypes = {
    navbarPosition: PropTypes.string,
    handleBurgerMenu: PropTypes.func,
    navbarCollapsed: PropTypes.bool,
};

export default NavbarTop;
