import {useContext, useEffect, useMemo, useState} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import is from "is_js";
import AppContext, {ExchangeContext} from "context/Context";
import AppRoutes from "routes";
import {CloseButton} from "components/common/Toast";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import {QueryClient, QueryClientProvider} from "react-query";
import AuthProvider from "AuthProvider";
import ErrorBoundaryWrapper from "components/ErrorBoundary";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            // refetchOnmount: false,
            // refetchOnReconnect: false,
            // retry: false,
        },
    },
});

const App = () => {
    const HTMLClassList = document.getElementsByTagName("html")[0].classList;
    const {
        config: {navbarPosition},
    } = useContext(AppContext);

    useEffect(() => {
        if (is.windows()) {
            HTMLClassList.add("windows");
        }
        if (is.chrome()) {
            HTMLClassList.add("chrome");
        }
        if (is.firefox()) {
            HTMLClassList.add("firefox");
        }
        if (is.safari()) {
            HTMLClassList.add("safari");
        }
    }, [HTMLClassList]);

    useEffect(() => {
        if (navbarPosition === "double-top") {
            HTMLClassList.add("double-top-nav-layout");
        }
        return () => HTMLClassList.remove("double-top-nav-layout");
    }, [navbarPosition]);

    // Exchange selection
    const [currentExchange, setCurrentExchange] = useState('all');
    const value = useMemo(
        () => ({currentExchange, setCurrentExchange}),
        [currentExchange]
    );

    return (
        <Router basename={process.env.PUBLIC_URL}>
            <QueryClientProvider client={queryClient}>
                <ErrorBoundaryWrapper>
                    <AuthProvider>
                        <ExchangeContext.Provider value={value}>
                            {useMemo(() => (
                                <>
                                    <AppRoutes/>
                                </>
                            ), [])}
                        </ExchangeContext.Provider>
                    </AuthProvider>
                </ErrorBoundaryWrapper>
                <ToastContainer
                    closeButton={CloseButton}
                    icon={false}
                    position={toast.POSITION.BOTTOM_LEFT}
                />
            </QueryClientProvider>
        </Router>
    );
};

export default App;
