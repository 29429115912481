import React, { Suspense, useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AppContext, { AuthContext, UserContext } from 'context/Context';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import Footer from 'components/footer/Footer';

import { Spinner } from 'react-bootstrap';
import MutatingDotsSpinner from 'components/common/MutatingDotsSpinner';


const MainLayout = () => {
  const { hash, pathname } = useLocation();

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);
  const {
    authState,
    authDispatch
  } = useContext(AuthContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <div className='content'>
        <NavbarTop />
        {/*------ Main Routes ------*/}
        <Suspense fallback={<div className='d-flex align-items-center justify-content-center w-100' style={{ height: '100vh' }}>
          <MutatingDotsSpinner />
        </div>}>
          <Outlet />
        </Suspense>
        <Footer />
      </div>
      {authState.billingLoading && <div style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw', display: 'flex', zIndex: 1030, justifyContent: 'center', alignItems: 'center', backgroundColor: '#00000014' }}>
        <MutatingDotsSpinner />
      </div>}
    </div>
  );
};

export default MainLayout;
