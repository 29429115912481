import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import Papa from "papaparse";
import dayjs from "dayjs";
import MutatingDotsSpinner from "components/common/MutatingDotsSpinner";

const RiskChart = () => {
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(true);

  const styles = {
    chart: {
      width: "100%",
      minHeight: "100vh",
      overflow: "hidden",
      height: "100%",
      background: "#F2F5F9",
    },
    loader: {
      height: 300,
    },
  };

  const csvUrl =
    "https://www.robuxio.com/wp-content/uploads/equity.csv";

  const isValidRow = (row) => {
    // Ensure the row has valid numerical values and no empty day fields
    return (
      row["day"] !== "" &&
      !isNaN(row["High Risk"]) &&
      !isNaN(row["Medium Risk"]) &&
      !isNaN(row["Low Risk"])
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(csvUrl);
        const csvData = response.data;

        Papa.parse(csvData, {
          header: true,
          complete: function (results) {
            const parsedData = results.data;

            // Filter out rows with invalid data
            const validData = parsedData.filter(isValidRow);

            const chartData = validData.map((row) => ({
              day: row["day"],
              "High Risk": parseFloat(row["High Risk"]).toFixed(2),
              "Medium Risk": parseFloat(row["Medium Risk"]).toFixed(2),
              "Low Risk": parseFloat(row["Low Risk"]).toFixed(2),
            }));

            const filteredChartData = chartData;

            console.log(chartData);
            const dates = filteredChartData.map((row) => row["day"]);
            const highRiskData = filteredChartData.map(
              (row) => row["High Risk"]
            );
            const mediumRiskData = filteredChartData.map(
              (row) => row["Medium Risk"]
            );
            const lowRiskData = filteredChartData.map((row) => row["Low Risk"]);

            setOptions({
              chart: {
                type: "line",
                height: 500,
                foreColor: "",
                background: "#F2F5F9",
                toolbar: { show: false },
              },
              series: [
                {
                  name: "High Risk",
                  data: highRiskData,
                },
                {
                  name: "Medium Risk",
                  data: mediumRiskData,
                },
                {
                  name: "Low Risk",
                  data: lowRiskData,
                },
              ],
              xaxis: {
                categories: dates,
                type: "datetime",
                labels: {
                  style: {
                    fontSize: "10px",
                    colors: ["#002745"],
                  },
                  formatter: function (val, timestamp) {
                    return dayjs(val).format("MMM 'YY");
                  },
                },
                tickAmount: 7,
              },
              yaxis: {
                labels: {
                  formatter: function (val) {
                    return val + "%";
                  },
                  style: {
                    colors: ["#002745"],
                  },
                },
                axisBorder: {
                  color: "",
                },
                axisTicks: {
                  color: "",
                },
              },
              stroke: {
                curve: "smooth",
                width: 3,
              },

              colors: ["#FF0000", "#FFA500", "#008000"],
              legend: {
                position: "bottom",
                horizontalAlign: "center",
              },
              grid: {
                show: false,
                borderColor: "#e0e0e0",
                strokeDashArray: 2,
              },
              tooltip: {
                shared: true,
                intersect: false,
                x: {
                  // Custom formatter for displaying the date in the tooltip
                  formatter: function (val) {
                    return dayjs(val).format("MMM D, YYYY");
                  },
                },
              },
            });
            setSeries([
              { name: "High Risk", data: highRiskData },
              { name: "Medium Risk", data: mediumRiskData },
              { name: "Low Risk", data: lowRiskData },
            ]);
          },
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.error("Error fetching the CSV data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-center ">
      <div id="robuxio-chart" style={styles.chart}>
        {loading ? (
          <div
            style={styles.loader}
            className="d-flex align-items-center justify-content-center"
          >
            <MutatingDotsSpinner />
          </div>
        ) : (
          <Chart options={options} series={series} type="line" height="100%" />
        )}
      </div>
    </div>
  );
};

export default RiskChart;
