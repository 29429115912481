import { AuthContext } from 'context/Context';
import { extractRoutes } from 'helpers/utils';
import React, { Suspense, useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import siteMaps from 'routes/siteMaps';

const Layout = () => {
	const location = useLocation();
	const {
		authState,
		authDispatch
	} = useContext(AuthContext);
	const navigate = useNavigate()

	let validRoutes = [];
	siteMaps.forEach(obj => {
		validRoutes = [...validRoutes, ...extractRoutes(obj)];
	});

	React.useEffect(() => {
		if (
			validRoutes.includes(location.pathname) &&
			location.pathname !== '/login'
		) {
			authDispatch({
				type: 'REDIRECTPATH',
				payload: location.pathname,
			});
		}
	}, [location.pathname]);

	// React.useEffect(() => {
	// 	if (authState.redirectToPath && authState.user) {
	// 		navigate(authState.redirectToPath)
	// 	}
	// }, [authState.user,authState.redirectToPath])



	return (
		<>
			<Outlet />
		</>

	);
};

export default Layout;
