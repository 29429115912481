
export const authReducer = (state, action) => {
	const { type, payload } = action;
	switch (type) {
		case 'SET_USER':
			return {
				...state,
				user: payload.user
			};
		case 'SET_BILLING_LOADING':
			return {
				...state,
				billingLoading: payload
			};
		case 'LOGOUT':
			localStorage.clear();
			return {
				...state,
				user: false
			};
		case 'REDIRECTPATH':
			return {
				...state,
				redirectToPath: payload
			};
		default:
			return state;
	}
};
