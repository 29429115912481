import React from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "api";
import Flex from "components/common/Flex";



function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleRecoverPassword = async() => {
    setLoading(true)
    try {
        await api.post('/reset_password', {email: email});
        toast.success('Email sent, check your email to recover your password');
    } catch (error) {
        toast.error('Failed to reset password: ' + error.message);
    } finally {
      setLoading(false)
    }
  };

  const handleSubmit=(e)=> {
    e.preventDefault()
    handleRecoverPassword()
  }

  return (
    <Form
    onSubmit={handleSubmit}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        className="fw-semi-bold fs-1 text-1000"
      >
        Forgot your password?
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        className="fs-sm--2 text-1000 mb-4 mt-1"
      >
        Enter your email and we'll send you a reset link
      </Flex>
      <Form.Group className="mb-3" id="login-form">
        {/* {hasLabel && <Form.Label>Forgot Password</Form.Label>} */}
        <Form.Control
          placeholder={"Email address"}
          value={email}
          name="email"
          onChange={(e)=>setEmail(e.target.value)}
          type="email"
        />
      </Form.Group>
      <Form.Group>
        <Button
          
          type="submit"
          color="primary"
          className="w-100 mt-3"
          //   disabled={!formData.username || !formData.password || loading}
        >
          {loading ? "Loading…" : "Send reset link"}
        </Button>
      </Form.Group>

      <a
        className="hover-text-decoration-underline text-1000"
        href="mailto:support@robuxio.com"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          className="fs--1 text-1000 mb-4 mt-1 mt-3"
        >
          I can't recover my account using this page
          <FontAwesomeIcon icon="arrow-right" style={{ marginLeft: "5px" }} />
        </Flex>
      </a>
    </Form>
  );
}

export default ForgotPassword;
