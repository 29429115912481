import { ErrorBoundary } from "react-error-boundary";
import React from 'react'
import Error500 from "./errors/Error500";

function ErrorBoundaryWrapper({ children }) {
	return (

		<ErrorBoundary fallback={<Error500 />}>
			{children}
		</ErrorBoundary>
	)
}

export default ErrorBoundaryWrapper