import api from 'api';

export async function logout() {
	try {
		const res = await api.post('/logout');
		localStorage.clear()
		return res.data;
	} catch (error) {
		return error
	}
}
