import React from 'react'
import { MutatingDots } from 'react-loader-spinner'

function MutatingDotsSpinner() {
	return (
		<MutatingDots
			height="100"
			width="100"
			color="#5e6e82"
			secondaryColor='#5e6e82'
			radius='12.5'
			ariaLabel="mutating-dots-loading"
			wrapperStyle={{}}
			wrapperClass=""
			visible={true}
		/>
	)
}

export default MutatingDotsSpinner