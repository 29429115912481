import React, {useState} from "react";
import api from "api";
import {toast} from "react-toastify";
import {Card, Col, Form, Row, Button} from "react-bootstrap";

function KuCoinV3() {
    const [isLoading, setLoading] = useState(true);
    const [formData, setFormData] = useState({});

    const handleFormChange = (e) => {
        const {id, value} = e.target;
        setFormData({...formData, [id]: value});
    };

    const validateForm = () => {
        // Base validation checks for all fields except the passphrase
        if (!formData.api_key || !formData.api_secret || !formData.passphrase) {
            const errorMessage = !formData.api_key
                ? "Please fill the API key" : !formData.api_secret ?
                    "Please fill the API secret" : "Please fill the passphrase";
            throw new Error(errorMessage);
        }
    }

    const updateSettings = async () => {
        // Posting the data to the /settings endpoint
        const response = await api.post('/kucoin_v3', formData);

        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form
        try {
            validateForm();
        } catch (error) {
            toast.error(error.message);
            return;
        }

        setLoading(true);
        try {
            await updateSettings();
            toast.success("API key has been changed successfully");
        } catch (error) {
            toast.error(`Unable to set API key: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mt-0 px-0">
            <Form onSubmit={handleSubmit} role={"form"} autoComplete="off">
                <Card className="mb-3">
                    <Card.Header>
                        <Row className="align-items-center">
                            <Col>
                                <h5 className="mb-0">Upgrade KuCoin v2 API keys to v3</h5>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="bg-light border-top">
                        <div className="table-responsive scrollbar">
                            <Form.Group className="mb-3" controlId="api_key">
                                <Form.Label>API key:</Form.Label>
                                <Form.Control onChange={handleFormChange} type="text" placeholder="Your API key here"
                                              value={formData.api_key} autoComplete="off"/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="api_secret">
                                <Form.Label>API secret:</Form.Label>
                                <Form.Control onChange={handleFormChange} type="password" placeholder="Your API secret here"
                                              value={formData.api_secret} autoComplete="off"/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="passphrase">
                                <Form.Label>Passphrase:</Form.Label>
                                <Form.Control onChange={handleFormChange} type="password"
                                              placeholder="Your passphrase here" value={formData.passphrase}
                                              autoComplete="off"/>
                            </Form.Group>
                        </div>
                    </Card.Body>
                    <Card.Footer className="border-top">
                        <Row className="align-items-end">
                            <Col xs="auto">
                                <Button variant="primary" type="submit">
                                    Save Changes
                                </Button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Form>
        </div>
    );
}

export default KuCoinV3;
