import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import TagManager from 'react-gtm-module';


const container = document.getElementById('main');
const root = createRoot(container);
const tagManagerArgs = {
  gtmId: 'GTM-N6ZSTC8D'
}



TagManager.initialize(tagManagerArgs);



root.render(
  <React.StrictMode>
    <Main>
      <App/>
    </Main>
  </React.StrictMode>
);
