import { createContext } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);

export const AuthContext = createContext({ user: null, billingLoading: false });
export const ExchangeContext = createContext({
  currentExchange: 'all',
  setCurrentExchange: () => {}
});

export default AppContext;
