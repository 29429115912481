import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileDropdown from "components/navbar/top/ProfileDropdown";
import AppContext from "context/Context";
import React, { useContext, useState } from "react";
import {Dropdown, Nav, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import ClickAwayListener from "react-click-away-listener";
import api from "api";
import {toast} from "react-toastify";
import {useQuery} from "react-query";
import {getExchangeName} from "../../../helpers/utils";
import classNames from "classnames";

const TopNavRightSideNavItem = () => {
  const {
    config: { isDark },
    setConfig,
  } = useContext(AppContext);
  const [show, setShow] = useState(false);

  const fetchHeaderData = async () => {
    try {
      const response = await api.get('/header');
      return response.data;
    } catch (error) {
      toast.error('Failed to retrieve data: ' + error.message);
    }
  };

  const { data, isLoading } = useQuery('header', fetchHeaderData, { refetchInterval: 30000 });

  const status = data ? Object.entries(data.exchanges)
      .filter(([exchange, _]) => exchange !== 'all')
      .map(([exchange, data]) => ({
        exchange: exchange,
        isOk: data.is_active
      })) : {};
  const numOk = data ? status.map(({isOk}) => isOk ? 1 : 0)
      .reduce((acc, val) => acc + val, 0) : 0;
  const globalStatus = numOk === 0 ? 0 : numOk === status.length ? 1 : null;

  const textColor = (s) => {
    return `text-${s === 1 ? 'success' : s === 0 ? 'danger' : 'warning'}`
  }

  return (
    <Nav
      navbar
      className="navbar-nav-icons align-items-center ms-auto flex-row"
      as="ul"
    >
      <Nav.Item key="status" className={"me-1"}>
        <Dropdown>
          <Dropdown.Toggle variant="light">
            {isLoading && <Spinner animation="border" role="status" size="sm"/> }
            {data &&
              <span className="fs--1">
                <FontAwesomeIcon
                    icon="circle"
                    className={classNames("me-1 fs--1", textColor(globalStatus))}/>
                <span className={"d-none d-md-inline"}>
                  {globalStatus === 1 ? 'Active' : globalStatus === 0 ? 'Inactive' : 'Partially active'}
                </span>
              </span>
            }
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {data && status.map(({exchange, isOk}) =>
                <Dropdown.Item>
                  <span className="">
                    <FontAwesomeIcon icon="circle"
                                     className={classNames("me-1 fs--1", textColor(+isOk))}/>
                    {getExchangeName(exchange)}</span>
                </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Item>
      <Nav.Item key={"theme"}>
        <ClickAwayListener onClickAway={() => setShow(false)}>
          <Nav.Link
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            onTouchStart={() => setShow(true)}
            className="theme-control-toggle px-2 pb-1"
            onClick={() => {
              setConfig("isDark", !isDark);
              setShow(false);
            }}
          >
            <OverlayTrigger
              key="left"
              show={show}
              placement={"bottom"}
              overlay={
                <Tooltip id="ThemeColor">
                  {isDark ? "Switch to light theme" : "Switch to dark theme"}
                </Tooltip>
              }
            >
              <div className="theme-control-toggle-label ">
                <FontAwesomeIcon
                  icon={isDark ? "sun" : "moon"}
                  className="fs-1"
                />
              </div>
            </OverlayTrigger>
          </Nav.Link>
        </ClickAwayListener>
      </Nav.Item>
      <Nav.Item key={"profile"}>
        <ProfileDropdown />
      </Nav.Item>
    </Nav>
  );
};

export default TopNavRightSideNavItem;
