export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      children: [
        {
          name: 'Overview',
          to: '/',
          exact: true,
          active: true
        },
        {
          name: 'Open positions',
          to: '/positions',
          active: true
        },
        {
          name: 'Closed trades',
          to: '/trades',
          active: true
        },
        // {
        //   name: 'Analytics',
        //   to: '/analytics',
        //   active: true
        // }

      ]
    }
  ]
};

export const subscriptionRoutes = {
  label: 'My products',
  labelDisable: true,
  children: [
    {
      name: 'My products',
      active: true,
      icon: 'wallet',
      children: [
        {
          name: 'Billing',
          to: '/billing',
          exact: true,
          active: true
        }
      ]
    }
  ]
};

export const settingsRoutes = {
  label: 'Settings',
  labelDisable: true,
  children: [
    {
      name: 'Settings',
      active: true,
      icon: 'cog',
      children: [
        {
          name: 'Account',
          to: '/account',
          exact: true,
          active: true
        },
        {
          name: 'Profiles',
          to: '/profile',
          exact: true,
          active: true
        },
      ]
    }
  ]
};


export const support = {
  label: 'Support',
  labelDisable: true,
  show: false,
  children: [
    {
      name: 'Support',
      active: true,
      icon: 'question-circle',
      children: [
        {
          name: 'FAQs',
          to: '/faqs',
          exact: true,
          active: true,
        },
        {
          name: 'Contact Us',
          to: '/contact-us',
          exact: true,
          active: true
        },
      ]
    },
  ]
};

export default [
  dashboardRoutes,
  subscriptionRoutes,
  settingsRoutes,
  support
];
