import {Link} from "react-router-dom";
import {Dropdown, Table} from "react-bootstrap";
import Avatar from "components/common/Avatar";
import {useQuery} from "react-query";
import api from "api";
import {logout} from "apis/auth";
import {capitalizeFirstLetter, getExchangeName} from "helpers/utils";


const ProfileDropdown = () => {
    const fetchProfileData = async () => {
        try {
            const response = await api.get("/profile");
            return response.data;
        } catch (error) {
            throw new Error("Network response was not ok");
        }
    };
    const {data, error, isLoading} = useQuery("profile", fetchProfileData);

    const sortedServices = data?.services.sort((a, b) => {
        if (!a.exchange) return 1;
        if (!b.exchange) return -1;
        return 0;
    });

    async function logoutUser() {
        await logout();
    }

    return (
        <Dropdown navbar={true} as="li">
            <Dropdown.Toggle
                bsPrefix="toggle"
                as={Link}
                to="#!"
                className="nav-link pe-0 ps-2"
            >
                <Avatar/>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
                {data && (
                    <div className="bg-white rounded-2 py-2 dark__bg-1000">
                        <Dropdown.Item disabled>
                            <span className="fw-semi-bold">Name: </span>
                            {data?.name}
                        </Dropdown.Item>
                        <Dropdown.Item disabled>
                            <span className="fw-semi-bold">Email: </span>
                            {data?.email}
                        </Dropdown.Item>
                        <Dropdown.Item disabled>
                            <span className="fw-semi-bold">Product: </span>
                            {data?.product}
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item disabled>
                            <Table striped bordered className="pb-0 mb-0" size="sm">
                                <tbody>
                                <tr>
                                    <th>Exchange</th>
                                    <th>Portfolio</th>
                                </tr>
                                {sortedServices.map((service, index) => {
                                    const exchangeName = service.exchange ? getExchangeName(service.exchange) : "Not set";
                                    return (
                                        <tr key={index}>
                                            <td>{exchangeName}</td>
                                            <td>
                                                {service.portfolio ?? "Not set"}
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </Table>
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        {data?.expiring_date &&
                            <div>
                                <Dropdown.Item disabled>
                                    <span className="fw-semi-bold">Renewal date: </span>
                                    {new Date(data.expiring_date).toLocaleDateString(undefined, {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                    })}
                                </Dropdown.Item>
                                <Dropdown.Divider/>
                            </div>
                        }
                        <Dropdown.Item as={Link} to="/account">
                            Account
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/profile">
                            Profile
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={logoutUser}
                            // as={Link} to="/authentication/card/logout"
                        >
                            Logout
                        </Dropdown.Item>
                    </div>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ProfileDropdown;
