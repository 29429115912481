import Logo from 'components/common/Logo';
import React from 'react';
import { Card } from 'react-bootstrap';

const Error500 = () => (
  <div style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
    <div className='d-flex justify-content-center align-items-center mb-1'>
      <Logo width={250} full />
    </div>
    <Card className="text-center mx-2 ">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">500</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Whoops, something went wrong!
        </p>
        <hr />
        <p>
          Try refreshing the page, or going back and attempting the action again.
          If this problem persists,
          <a href='mailto:support@robuxio.com' className="ms-1">
            contact us
          </a>
          .
        </p>
      </Card.Body>
    </Card>
  </div>
);

export default Error500;
