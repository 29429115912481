import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from 'context/Context';
import { authReducer } from 'reducers/authReducer';
import { exchangeReducer } from 'reducers/exchangeReducer';

const AuthProvider = ({ children }) => {
	const [authState, authDispatch] = useReducer(authReducer, {
		user: null,
		redirectToPath: null,
		billingLoading: false
	});
	
	return (
		<AuthContext.Provider value={{ authState, authDispatch }}>
			{children}
		</AuthContext.Provider>
	);
};



AuthProvider.propTypes = { children: PropTypes.node };

export default AuthProvider;
